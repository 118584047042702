const Gracias = () => {
  return (
    <div className=" px-6 mt-16 text-center">
      <div className="text-xl text-gray-700">
        <p>Gracias por tus comentarios. </p>
      </div>
        <div className=" px-6 mt-8 text-center">
          <p>Tus sugerencias, críticas o comentarios nos ayudan a mejorar día con día. ¡Muchas gracias!.</p>

         <p>Recuerda, si deseas una respuesta o hacer otra pregunta responde directamente <a className="text-blue-700 underline" href='https://eleventa.com/soporte'>por correo electrónico</a>,
            ya que no respondemos directamente a estos comentarios y/o preguntas.</p>
        </div>
    </div>
  )
}

export default Gracias;
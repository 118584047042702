import { Header } from '../components/commons';

const Main = ({ children }) => (
    <div className="bg-white">
      <div className="relative">
        <Header/>
        {children}
      </div>
    </div>
  );

export default Main;

// let backEndUrl = 'http://127.0.0.1:4000/api/v1/'; // For debug
// let backEndUrl = 'https://crm-b-staging.bambucode.com/api/v1/';

const host = process.env.PUBLIC_URL;
const backEndUrl = process.env.REACT_APP_BACKEND_SERVER;

export function getAPIBackendURL() {
  return backEndUrl;
}

export function initializeRequiredComment(type){
  let res = false;
  if (type === 'malo' || type === 'regular' || type === 'm' || type === 'r') {
    res = true;
  }
  return res;
}

export function validate_email(email) {
  // Ref: https://regex101.com/r/OyyaXF/1
  const regex = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z]{1,8})|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/;

  let res  = true;
  if (email !== null && email !== undefined) {
    email = email.toLowerCase().trim();
    if (!email) { // Email vacío
      res = false;
    } else if (!regex.test(email)) { // Email válido
      res = false;
    }
  }

  //console.log('validate_email = ' + email + ' valid? ' + res);
  return res;
}

export function validate_msg(input) {
  let res = true;
  if (!input.includes('msg') || input === 'msg_0') {
    res = false;
  }
  return res;
}

export function validate_value(input){
  let res = true;
  if (!['bueno', 'regular', 'malo','b','r','m'].includes(input)) {
    res = false;
  }
  return res;
}

export function validate_comment(input) {
  if (input.trim() === '')  {
    return false;
  } else {
    return true;
  }
}


import React, { useEffect, useState } from "react";

const CommentArea = ({ comments, setParentComment, submitData }) => {

  const [comment, setComment] = useState(comments);

  useEffect(() => {
    setComment(comments);
  }, [comments]);

  return (
    <form>
      <div className="mt-1">
        <textarea
          placeholder="Mi experiencia de soporte fue..."
          rows={4}
          name="comment"
          id="comment"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-4 border-dashed"
          value={comment || ""}
          onChange={(e) => { setParentComment(e.target.value); }}
        />
      </div>
      <div className="mt-2 flex justify-end">
        <button
          type="button"
          className="inline-block btn-ultraviolet-rays-1 mt-1.5 px-3 py-1  text-white font-medium bg-no-repeat border rounded"
          onClick={() => { submitData(comment); }}>
          Compartir
        </button>
      </div>
    </form>
  )
}

export default CommentArea;
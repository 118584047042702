import React from "react";
import { useRoutes } from "react-router-dom";
import { Main } from "../layouts";
import { EmptyState } from "../components/commons";
import { EvaluationForm } from "../views/forms";
import { Gracias } from "../views";

const Routes = () => {
  let routes = useRoutes([ 
    {
      path: "/",
      exact: true,
      layout: <Main/>,
      element: <EmptyState/>
    },
    {
      path: "/gracias",
      layout: <Main/>,
      element: <Gracias/>
    },
    {
      path: "/:agente/:front_public_id/:type",
      exact: true,
      layout: <Main/>,
      element: <EvaluationForm/>
    },
    {
      path: "/eleventa2/:agente/:ignore/:front_public_id/:type",
      exact: true,
      layout: <Main/>,
      element: <EvaluationForm/>
    },
    {
      path: "/e/:agente/:type/:front_public_id",
      exact: true,
      layout: <Main/>,
      element: <EvaluationForm/>
    },
    {
      path: '*',
      layout: <Main/>,
      element: <EmptyState/>
    }
  ]);
  return routes;
};

export default Routes;

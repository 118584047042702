import React, { Fragment, useState, useEffect } from "react";

const Evaluation = ({ types }) => {
  const [type, setType] = useState(types);

  useEffect(() => {
    setType(types);
  }, [types]);

  return (
    <div className="mb-4 flex flex-col justify-center">
      <h1 className="mb-1 text-2xl text-center text-gray-eleventa">¡Gracias por evaluar nuestro soporte técnico!</h1>
      { type === "bueno" && (
        <Fragment>
          <img className="mb-6 mx-auto w-20 rounded-xl shadow-md rounded-full" src="https://opinion.bambucode.com/images/voto-bueno.png" alt="" />
          <h2 className="mb-1 text-1xl text-center text-gray-eleventa">Nos complace saber que tuviste una experiencia excelente</h2>
        </Fragment>
      )}
      { type === "regular" && (
        <Fragment>
          <img className="mb-6 mx-auto w-20 rounded-xl shadow-md rounded-full" src="https://opinion.bambucode.com/images/voto-regular.png" alt="" />
          <h2 className="mb-1 text-1xl text-center text-gray-eleventa">Votaste regular, la próxima vez queremos ser excelentes, platícanos como podemos mejorar...</h2>
        </Fragment>
      )}
      { type === "malo" && (
        <Fragment>
          <img className="mb-6 mx-auto w-20 rounded-xl shadow-md rounded-full" src="https://opinion.bambucode.com/images/voto-malo.png" alt="" />
          <h2 className="mb-1 text-1xl text-center text-gray-eleventa">Lamentamos que hayas tenido una mala experiencia, queremos mejorar... ¿qué fué lo que no te gusto de nuestro soporte?</h2>
        </Fragment>
      )}
    </div>
  )
}

export default Evaluation;
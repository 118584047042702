const EmptyState = () => {
  return (
    <div className="px-6 mt-16 text-center">
      <div className="text-xl text-gray-700">
        <p>No es posible realizar una evaluación en este momento. </p>
        <p>Por favor, intente más tarde o contacte a <a className="text-xl text-blue-700 underline" href='https://eleventa.com/soporte'>Soporte</a>.
        </p>
      </div>
    </div>
  )
}

export default EmptyState;
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Main } from "./layouts";
import { EmptyState } from './components/commons';
import Routes from "./utils/Routes";

const App = () => (
  <Suspense fallback={<EmptyState/>}>
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <Main>
        <Routes/>
      </Main>
    </Router>
  </Suspense>
);

export default App;

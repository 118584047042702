const Header = () => {
  return (
    <header className="sticky top-0 z-50">
      <nav className="max-w-max lg:max-w-4.5xl xl:max-w-6.5xl mx-2.5 lg:mx-auto" aria-label="Top">
        <div className="w-full pt-1.5 pb-2 flex items-start lg:items-center justify-between">
          <a href="https://eleventa.com/" className="my-2.5">
            <span className="sr-only">eleventa punto de venta</span>
            <img className="h-13 w-auto" src="https://assets.eleventa.com/assets/img/logoeleventa.png" alt="" />
          </a>
        </div>
      </nav>
    </header>
  )
}

export default Header;
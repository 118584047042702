import { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { initializeRequiredComment, getAPIBackendURL, validate_msg, validate_value } from '../../utils/helper';

import { Divider, EmptyState, SocialNetworks } from '../../components/commons';
import { CommentArea, Evaluation } from '../../components/evaluations';


const EvaluationForm = () => {
  const navigate = useNavigate();
  const { front_public_id } = useParams();
  let   { agente, type } = useParams();
  const [requiredComment] = useState(initializeRequiredComment(type));
  const [data, setData] = useState("");
  const [comment, setComment] = useState("");
  const [alert] = useState("Te agradeceriamos dejarnos un comentario para dar seguimiento a tu caso");

  useEffect(() => {
    if (agente && validate_msg(front_public_id) && validate_value(type)) {
      if (agente.includes("@bambucode.com") || agente.length > 2) {
        getSignature(agente);
        setTimeout(() => {
          if (agente == null || agente.length > 2) {
            navigate('/');
          } else {
          navigate(`/e/${agente}/${type}/${front_public_id}`);
          fetchData();
          }
        }, 600);
      } else {
        fetchData();
      }
    } else {
      navigate("/");
    }
  }, []);

  const getSignature = useCallback( async () => {
    const url = getAPIBackendURL() + 'evaluation_get_signature';

    let body = JSON.stringify(
      { agente }
    );

    try {
      const response = await fetch( url, 
        { method: 'POST', 
          body 
        });
      const json = await response.json();
      agente = json.agente;
    } catch (error) {
      navigate('/');
    }
  });

  const fetchData = useCallback( async () => {
    const url = getAPIBackendURL() + 'evaluation_get_create';

    switch (type) {
      case 'b':
        type = 'bueno';
        break;
      case 'r':
        type = 'regular';
        break;
      case 'm':
        type = 'malo';
        break;
      default:
        type = type;
        break;
    }

    let body = JSON.stringify(
      { agente, front_public_id, type }
    );

    try {
      const response = await fetch( url, 
        { method: 'POST', 
          body 
        });
      const json = await response.json();
      setData(json);
      setComment(json.comment);
    } catch (error) {
      navigate('/');
    }
  });

  const submitData = useCallback(async () => {
    if (!requiredComment || comment) {
      const url = getAPIBackendURL() + 'evaluation_save_update';

      let body = JSON.stringify(
        { agente, front_public_id, comment }
      );

      try {
        const response = await fetch( url, 
          { method: 'POST', 
            body 
          });
        const json = await response.json();
        navigate('/gracias', {replace: true});
      } catch (error) {
        navigate('/');
      }
    }
  });

  return (
    <Fragment>
      <div className="px-6 mt-16 py-10">
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {agente || front_public_id || type ? (
            <Fragment>
              <Evaluation types={data.value}/>
              <Divider/>
              { data.value === "bueno" && (
                <Fragment>
                  <SocialNetworks/>
                </Fragment>
              )}
              { requiredComment && !comment ? (<div className="text-red-500" >{alert}</div>) : null }
              <CommentArea comments={comment} setParentComment={setComment} submitData={submitData}/>
            </Fragment>
          )
          : (
            <EmptyState/>
          )}
          </div>
        </main>
      </div>
    </Fragment>
  )
};

export default EvaluationForm;
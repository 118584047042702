import { Fragment } from 'react';

const SocialNetworks = () => {
  return (
    <Fragment>
      <br/>
      <div align="center">
        <h3 className="mb-1 text-1xl text-center text-gray-eleventa">Síguenos en redes sociales</h3>
        <div className="mx-auto mb-10 flex justify-center space-x-5">
          <div className="text-center group">
            <a href="https://opinion.bambucode.com/images/facebook-logo-5608e89a8cb5949721bfd6888b0de96a.png?vsn=d" target="_blank" rel="noreferrer">
              <img className="h-16 rounded-0.5xl group-hover:shadow-blue-shadow" src="https://opinion.bambucode.com/images/facebook-logo-5608e89a8cb5949721bfd6888b0de96a.png?vsn=d" alt=""/>
            </a>
          </div>
          <div className="text-center group">
            <a href="https://opinion.bambucode.com/images/twitter-logo.png" target="_blank" rel="noreferrer">
              <img className="h-16 rounded-0.5xl group-hover:shadow-blue-shadow" src="https://opinion.bambucode.com/images/twitter-logo.png" alt=""/>
            </a>
          </div>
          <div className="text-center group">
            <a href="https://opinion.bambucode.com/images/twitter-logo.png" target="_blank" rel="noreferrer">
              <img className="h-16 rounded-0.5xl group-hover:shadow-blue-shadow" src="https://opinion.bambucode.com/images/youtube-logo.png" alt=""/>
            </a>
          </div>
          <div className="text-center group">
            <a href="https://opinion.bambucode.com/images/twitter-logo.png" target="_blank" rel="noreferrer">
              <img className="h-16 rounded-0.5xl group-hover:shadow-blue-shadow" src="https://opinion.bambucode.com/images/boletin.png" alt=""/>
            </a>
          </div>
        </div>
        <h1 className="mb-1 text-2xl text-center text-gray-eleventa">... y compártenos lo que más te gusto de nuestro soporte.</h1>
      </div>
      <br/>
    </Fragment>
  )
}

export default SocialNetworks;